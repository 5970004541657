import React, { useEffect } from "react"
import PreHeader from "../components/preHeader"
import HeaderDrak from "../components/headerDrak"
import Footer from "../components/footer"
import { Container, Row, Col } from "react-bootstrap"
import backBtn from "../images/arrow-grren-outline.svg"
import "../styles/main.scss"

// import for translate
import {
  AUTH_TOKEN_KEY,
  COLOR_GREEN,
  COLOR_RED,
  DOMAIN_API,
  TYPE_ACTU,
  TYPE_ADVANTAGE,
  TYPE_ALL,
  URL_GET_USER_FULL,
  URL_GETALL_ADVANTAGES,
  URL_GETALL_ADVICEES, URL_GETALL_FOLDERS, URL_GETALL_NEWS, URL_GETALL_VIDEOS, URL_GETALL,
  URL_IS_AUTH, translate, URL_GET_MAINPARTNERSUPER_DATA, URL_GETALL_PARTNER_SUPER_FILTERED, getUserLanguage, NL
} from "../utils"
import TagManager from "react-gtm-module"

const PrivacyTerms = () => {

  useEffect(() => {
    const userLanguage = getUserLanguage()
    if (userLanguage === NL) window.location.href = '/site-terms-nl'
  },[])

  return (
    <div>
      <PreHeader />
      <div className="privacyTerms">
        <HeaderDrak />
        <div className="pageContent">
          <Container>
            <Row className="justify-content-center align-items-lg-end">
              <Col lg="8" className="order-lg-2">
                <h1 className="h0-title yellow-privacy">
                <span className="d-block">
                Conditions d’utilisation du site web</span>
                </h1>

                {/* paragraphe one start */}
                <h2 className="h2-title yellow-privacy">ARTICLE 1 : MENTIONS LEGALES</h2>
                <p className="yellow-privacy">
                  Le Site Web <a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com </a>
                  est exploité par la société In Tempore sl (ci-après « In Tempore»), dont le siège social est situé avenue Kersbeek 306 1180 Bruxelles immatriculées à la BCE sous le numéro BE 0477 292 458. En accédant au Site Web et en l'utilisant, vous acceptez les présentes conditions d'utilisation (ci-après les « Conditions d’Utilisation ») sans aucune réserve et vous vous engagez à les respecter.                </p>

                {/* paragraphe one end */}

                {/* paragraphe two start */}
                <h2 className="h2-title  yellow-privacy">ARTICLE 2 : OBJET</h2>
                <p className="yellow-privacy">
                  Les présentes « conditions générales d'utilisation » ont pour objet l'encadrement juridique des modalités de mise à disposition des services du site <a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com </a>
                  ci-après dénommé « le Site Web », et leur utilisation par « l'Utilisateur ».
                  <p className="yellow-privacy">
                    Les conditions générales d'utilisation doivent être acceptées par tout Utilisateur souhaitant accéder au Site Web. L’accès au Site Web par l’Utilisateur signifie son acceptation des présentes conditions générales d’utilisation.
                  </p><p className="yellow-privacy">
                  En cas de non-acceptation des conditions générales d'utilisation stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à l'accès des services proposés par le Site Web.
                </p><p className="yellow-privacy">
                  In Tempore se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes conditions générales d'utilisation.
                </p></p><p className="yellow-privacy">
                Si l'une des dispositions des présentes Conditions d'Utilisation est inapplicable ou contraire à une disposition légale impérative, cette inapplicabilité n'aura aucun effet sur la validité ou l'applicabilité des autres dispositions.
                <p className="yellow-privacy">
                  Les présentes Conditions d'Utilisation sont des conditions spécifiques qui ont uniquement trait à l'utilisation du Site Web et doivent être complétées des conditions générales ainsi que de la Politique de confidentialité d’In Tempore qui restent intégralement d'application à la relation juridique entre In Tempore et les utilisateurs du Site Web.
                </p>
                <p className="yellow-privacy">Cela signifie qu’ ENTER-BUSINESS définit les finalités et les moyens du traitement de vos données à caractère personnel.</p>
              </p>

                {/* paragraphe two end */}

                {/* paragraphe three start */}
                <h2 className="h2-title yellow-privacy">ARTICLE 3 : DEFINITIONS</h2>
                <p className="yellow-privacy">
                  La présente clause a pour objet de définir les différents termes essentiels du contrat :
                  <p className="yellow-privacy"></p>
                  Utilisateur : ce terme désigne toute personne qui utilise le site ou l'un des services proposés par le Site Web.
                </p><p className="yellow-privacy">
                Contenu utilisateur : ce sont les données transmises par l'Utilisateur au sein du Site Web.
              </p><p className="yellow-privacy">
                Membre : l'Utilisateur devient membre lorsqu'il est identifié sur le Site Web.
              </p><p className="yellow-privacy">
                Identifiant et mot de passe : c'est l'ensemble des informations nécessaires à l'identification d'un Utilisateur sur le Site Web. L'identifiant et le mot de passe permettent à l'Utilisateur d'accéder à des services réservés aux membres du Site Web. Le mot de passe est confidentiel.
              </p>


                {/* paragraphe three ends */}





                {/* paragraphe four starts */}
                <h2 className="h2-title yellow-privacy">ARTICLE 4 : ACCÈS</h2>
                <p className="yellow-privacy">
                  Le site permet à l'Utilisateur un accès gratuit aux services du Site Web.
                </p><p className="yellow-privacy">Le Site Web est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.

              </p><p className="yellow-privacy">In Tempore met tout en œuvre pour, dans la mesure du possible, assurer l'accès au Site Web.

              </p><p className="yellow-privacy">Cet accès peut toutefois être interrompu, notamment en cas de maintenance et de mise à jour, en cas de panne ou pour d'autres raisons techniques.

              </p><p className="yellow-privacy">In Tempore décline donc toute responsabilité en cas de pannes, d'interruptions ou d'erreurs présentes sur le Site Web de même que pour les conséquences qui pourraient en découler pour l'utilisateur ou des tiers.
              </p>

                {/* paragraphe four ends */}

                {/* paragraphe five starts */}
                <h2 className="h2-title yellow-privacy">ARTICLE 5 : PROPRIÉTÉ INTELLECTUELLE</h2>


                <p className="yellow-privacy">
                  Le Site Web, y compris les textes, la structure, la mise en pages, les composantes graphiques, la présentation, les logos, le logiciel et l'ensemble d'autres éventuels éléments contenus dans le Site Web, sont protégés par les droits de propriété intellectuelle de l’association, ses éventuels fournisseurs ou partenaires. Ces droits de propriété intellectuelle concernent entre autres, sans que cette énumération ne soit limitative : le droit d'auteur, les droits voisins, les droits afférents aux banques de données et les droits des marques.
                </p>

                <p className="yellow-privacy">
                  D'autres noms de produits ou d'entreprises mentionnés sur le Site Web peuvent être des marques de leurs propriétaires respectifs. Sans l'autorisation écrite et préalable de l'ayant droit ou de l’association, il est interdit à l'utilisateur de modifier, copier, distribuer, communiquer, traduire, diffuser, reproduire, publier, accorder avec licence, céder ou vendre les informations, logiciels, produits ou services obtenus sur ces sites. Il lui est également interdit de créer des travaux dérivés des éléments susmentionnés.
                </p>

                {/* paragraphe five ends */}


                {/* paragraphe six starts */}
                <h2 className="h2-title yellow-privacy">ARTICLE 6 : RESPONSABILITÉ ET FORCE MAJEURE</h2>

                <p className="yellow-privacy">Les sources des informations, produits et services présentés sur le Site Web sont réputées fiables. Toutefois, In Tempore n'accorde aucune garantie quelle qu'elle soit concernant l'exactitude ou l'actualisation des informations proposées sur le Site Web. Les informations données sur le Site Web le sont à titre purement informatif. Ainsi, l'Utilisateur assume seul l'entière responsabilité de l'utilisation des informations et contenus du présent Site Web.
                </p><p className="yellow-privacy">
                Tout usage du service par l'Utilisateur ayant directement ou indirectement pour conséquence des dommages doit faire l'objet d'une indemnisation au profit de In Tempore.</p><p className="yellow-privacy">Il va de soi que cet intérêt doit être constamment mis en balance avec les droits des personnes enregistrées dans notre fichier. C’est à cette fin que nous veillons à être le plus transparent possible quant à l’utilisation des données et nous respectons scrupuleusement le RGPD quant aux droits de la personne.
              </p><p className="yellow-privacy">
                Une garantie optimale de la sécurité et de la confidentialité des données transmises n'est pas assurée par le Site Web / In Tempore. Toutefois, In Tempore s'engage à mettre en œuvre les moyens raisonnables afin de garantir au mieux la sécurité et la confidentialité des données.
              </p><p className="yellow-privacy">
                La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers. Dès lors, In Tempore ne peut notamment être tenue responsable de la non-exécution des contrats conclus grâce à l’accès à son Site Web, ou d'une indisponibilité d’un service ou de cas de force majeure.</p><p className="yellow-privacy">
              </p><p className="yellow-privacy">
                L'Utilisateur s'oblige donc à ne réclamer aucune indemnisation suite à l'interruption, à la suspension ou à la modification des présentes conditions d’utilisation.
              </p>
                {/* paragraphe six ends */}




                {/* paragraphe seven starts */}
                <h2 className="h2-title yellow-privacy">ARTICLE 7 : HYPERLIENS</h2>
                <p className="yellow-privacy">
                  Les sites internet vers lesquels les utilisateurs du Site Web peuvent être renvoyés par le biais de d’hyperliens restent l'entière responsabilité des titulaires de ces sites.
                </p><p className="yellow-privacy">
                In Tempore décline toute responsabilité concernant le matériel et les données de quelque nature que ce soit, contenues dans ou diffusées sur ces sites internet et concernant le traitement de données à caractère personnel qui y est exécuté. En effet, In Tempore n’est responsable que du traitement des données à caractère personnel effectué sur son propre Site Web, tel que mentionné dans la Politique de confidentialité.
                Comme nous vous l’avons déjà indiqué précédemment, il va de soi que si vous souhaitez soit que nous n’utilisions plus vos données à des fins publicitaires (droit d’opposition) soit être oublié, il vous suffit de suivre la procédure décrite ci-dessous. Dans ce cas, le délai de 15 ans n’est évidemment pas applicable et votre volonté primera.
              </p>


                {/* paragraphe seven ends */}

                {/* paragraphe eight starts */}
                <h2 className="h2-title yellow-privacy">ARTICLE 8 : DONNÉES PERSONNELLES</h2>
                <p className="yellow-privacy">
                  Le Site Web assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément (i) au Règlement européen (UE) 2016/679 du 27 avril 2016 (Règlement Général sur la Protection des Données entré en vigueur le 25 mai 2018 - le « RGPD ») entré en vigueur le 25 mai 2018 et abrogeant partiellement la loi belge du 8 décembre 1992 relative à la protection de la vie privée à l'égard des traitements de données à caractère personnel jusque-là en vigueur et (ii) à la (future) législation belge relative à la mise en œuvre du Règlement susmentionné.
                </p><p className="yellow-privacy">
                Pour tout ce qui est relatif au traitement des données à caractère personnel de l’Utilisateur, ce dernier est renvoyé vers la Politique de confidentialité.
              </p>

                {/* paragraphe eight ends */}



                {/* paragraphe nine starts */}
                <h2 className="h2-title yellow-privacy">ARTICLE 9 : DROIT APPLICABLE ET COMPÉTENCE</h2>

                <p className="yellow-privacy">
                  Les présentes Conditions d’Utilisation sont entièrement et exclusivement soumises au droit belge.
                </p>
                <p className="yellow-privacy">
                  Pour tout litiges relatifs à l’exécution ou à l’interprétation des présentes Conditions d’Utilisation, seuls seront compétent les tribunaux du ressort du siège social de In Tempore.</p>
                {/* paragraphe nine ends */}


              </Col>
              {/*<Col lg="2" className="text-center mt-5">
                <a href={'/feed'} className="btn BackBtn">
                  <img
                    className="img-fluid"
                    src={backBtn}
                    alt="Enter Business"
                  />
                  <span>Back</span>
                </a>
              </Col>*/}
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  )
}


export default PrivacyTerms



